/* fonts */
@font-face {
  src: local('Graphik'), url(./assets/fonts/Graphik-Regular.otf) format('opentype');
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 400;
}

@font-face {
  src: local('Graphik'), url(./assets/fonts/Graphik-Semibold.otf) format('opentype');
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 600;
}


html {
  box-sizing: border-box;
  background: white;
}

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Graphik';
}

::scrollbar {
  display: none;
}

body {
  margin: 0;
  padding: 0;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Graphik';
  font-style: normal;
}

p {
  margin: 0;
}


